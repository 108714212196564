<template>
    <div>
        <div class="header">
            <span class="header_txt">直播福袋</span>
        </div>
        <div class="header_box">
            <div class="left">
                <img src="@/assets/live.png" alt="">
                <span class="left_span">选择直播：</span>
                <Select v-model="storePrams.live_id" style="width:200px" @on-change="liveChange">
                    <Option v-for="item in liveList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </div>
            <div class="right">
                <button class="header_btn" @click="addClass">添加福袋</button>
            </div>
        </div>
        <div class="content">
            <Table :columns="liveTable" :data="dataTable" :no-data-text="noDataText" border>
                <template slot-scope="{ row, index }" slot="type">
                    <span v-if="row.original_status != 1" class="infoModal_text" @click="edit(row)">编辑</span>
                    <span v-if="row.original_status != 1" class="infoModal_text" @click="push(row)">推送</span>
                    <span v-if="row.original_status == 1" class="infoModal_text">已抽奖</span>
                    <span v-if="row.original_status == 1" class="infoModal_text" @click="details(row)">中奖详情</span>
                </template>
            </Table>
        </div>
        <div class="footer">
            <Page :total="total" show-elevator show-total @on-change="pageChange"/>
        </div>
        <!-- 中奖详情弹框 -->
        <Modal v-model="detailsModal" title="中奖名单" width="50%">
            <div class="detailsModal_content">
                <Table :columns="detailsTable" :data="detailsDataTable" border :no-data-text="noDataText">
                    <template slot-scope="{row}" slot="nickname">
                        <span>{{row.customer.nickname}}</span>
                    </template>
                    <template slot-scope="{row}" slot="portrait">
                        <div style="display:flex;align-items:center;height: 56px;justify-content:center">
                            <img v-if="row.customer.portrait" :src="row.customer.portrait" alt="" style="width: 50px;">
                            <div v-else style="width: 56px;height: 56px;background-color: #f5f7fa;color:#c0c4cc;line-height:56px;text-align:center;">加载失败</div>
                        </div>
                    </template>
                </Table>
            </div>
            <div class="footer">
                <Page :total="totalModal" show-elevator show-total @on-change="pageChangeModal"/>
            </div>
        </Modal>
    </div>
</template>

<script>
import {liveSelect, bagList, startBag, bagInfoList} from '@/api/index'
    export default {
        data() {
            return {
                liveList: [],
                //table
                liveTable: [
                    {title: '抽奖名称',key: 'name',align:'center'},
                    {title: '中奖人数',key: 'num',align:'center'},
                    {title: '参与评论',key: 'comment',align:'center'},
                    {title: '创建时间',key: 'created_at',align:'center'},
                    {title: '操作',slot: 'type',align:'center'},
                ],
                dataTable: [],
                //page
                total: 1,
                storePrams: {
                    page: 1,
                    limit: 30,
                    live_id: 1
                },
                totalModal:3,
                storePramsModal: {
                    page: 1,
                    limit: 30,
                    bag_id:'',
                },
                noDataText:'暂无数据',
                detailsModal:false,
                detailsTable: [
                    {title: '姓名',slot: 'nickname',align:'center'},
                    {title: '头像',slot: 'portrait',align:'center'},
                    {title: '参与时间',key: 'created_at',align:'center'}
                ],
                detailsDataTable: []
            }
        },
        mounted(){
            this.liveSelect()
            this.bagList()
        },
        methods: {
            pageChangeModal(index){
                this.storePramsModal.page = index;
            },
            push(item){
                console.log('推送',item)
                let data ={
                    admin_id: item.admin_id,
                    id: item.id
                }
                startBag(data).then(res => {
                    this.bagList()
                    this.$Message.success(res.msg);
                })
            },
            // 直播福袋列表
            bagList(){
                bagList(this.storePrams).then(res => {
                    console.log('直播福袋列表',res.data.data)
                    this.total = res.data.total
                    this.dataTable = res.data.data
                })
            },
            // 选择直播
            liveSelect(){
                liveSelect().then(res => {
                    this.liveList = res.data
                })
            },
            liveChange(e){
                console.log('直播间eee',e)
                this.storePrams.live_id = e
                this.bagList()
            },
            addClass(){
                this.$router.push({path: '/live/liveIn/liveinbag/setUp?liveid='+this.storePrams.live_id})           
            },
            edit(item){
                console.log('编辑',item)
                this.$router.push({path: '/live/liveIn/liveinbag/editSetup?id='+item.id})
            },
            pageChange(index) {
                this.storePrams.page = index;
            },
            details(item){
                this.storePramsModal.bag_id = item.id
                this.detailsModal = true
                bagInfoList(this.storePramsModal).then(res => {
                    console.log('中奖详情', res.data.data)
                    this.totalModal = res.data.total
                    this.detailsDataTable = res.data.data
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
.header{
    width: 100%;
    height: 55px;
    padding: 16px;
    background-color: #cee0f9;
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px;
    display: flex;
    align-items: center;
}
.header_txt{
    font-size: 20px;
    font-weight: bold;
    color: #000;
}
.header_box{
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}
.header_btn{
    width: 100px;
    padding: 10px;
    background-color: #409eff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.left{
    display: flex;
    align-items: center;
}
.footer{
    margin: 20px 0;
    text-align: center;
}
.left_span{
    height: 21px;
    color: #374a70;
    font-weight: 700;
    font-size: 16px;
    margin-right: 4px;
    margin-left: 2px;
}
::v-deep .ivu-select-selection{
    height: 40px;
}
::v-deep .ivu-select-selected-value{
    height: 40px !important;
    line-height: 40px !important;
}
::v-deep .ivu-select-placeholder{
    height: 40px !important;
    line-height: 40px !important;
}
.infoModal_text{
    color: #409eff;
    margin: 10px;
    font-size: 12px;
    cursor: pointer;
}
.detailsModal_content{
    min-height: 500px;
}
</style>